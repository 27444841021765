import React from "react";
import Head from "next/head";
import err from "../images/404.png";
import {FormattedMessage} from "react-intl";
import Link from "next/link";
import {domainUrl} from "../helper";
import Image from "next/image";
import {useSelector} from "react-redux";
import {IState} from "../types/state";


const NotFound = () => {
    const dbName = useSelector((state: IState) => state.general.dbName)

    return (
        <div className="block">
            <Head>
                <title>404 Page Not Found</title>
            </Head>
            <div className="container">
                <div className="not-found__con">
                    <div className="not-found__con__image">
                        <Image
                            src={domainUrl(`${dbName}${err.src}`)}
                            alt="error page image"
                            placeholder="blur"
                            layout={"fill"}
                            blurDataURL={`/zega/blur1.webp`}
                            objectFit='contain'
                        />
                    </div>
                    <div className="not-found__con__text">
                        <div className="text-con">
                            <p className="not-found__con__text_p">
                                <FormattedMessage
                                    id="try.use.seach"
                                    defaultMessage="Whoops... this page is not available"
                                />
                            </p>
                            <p className="not-found__con__small_p">
                                <FormattedMessage
                                    id="try.use.search404"
                                    defaultMessage="Sorry, but the page you're looking for isn't available. Try to search again or use the Go Back button below."
                                />
                            </p>
                            <Link href="/" className="not-found__con__text_a">
                                <a>
                                    <FormattedMessage
                                        id="go.home.page"
                                        defaultMessage="Back home"
                                    />
                                </a>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default NotFound;